import React, { useContext, useEffect, useState } from "react";
import { DatasheetContext } from "../datasheet.context";
import { BASE_URL, MAX_COLS } from "../../../../global";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import YouTubeIcon from "@mui/icons-material/YouTube";
import moment from "moment";


import {
  UNCERTAINTY_LINK,
  EXTRA_COLUMN_LINK,
} from "../../../master/staticTable/editTable";
import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  Modal,
  IconButton,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Checkbox,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandCircleDownOutlined from "@mui/icons-material/ExpandCircleDownOutlined";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import {
  ClassicTable,
  ClassicTableDatasheet,
} from "../../../../utils/components/Styles";
import { showComponent } from "../../../helper/helpers";
import StandardMasterSelection from "../StandardMasterSelection";
import {
  fetchCertificateStaticTables,
  fetchDatasheetStaticTables,
  fetchStaticReadingRows,
  fetchbeforeAutoloadJobNumber,
  fetchafterAutoloadJobNumber,
} from "../fetchHandlers";
import { Box } from "@mui/system";
import RowDetailsModal from "./rowDetailsModal";
import axiosWithToken from "../../../../utils/components/axiosTokenConfig";
import { multiValueModalStyle } from "../../../../constants/srfConstants";
import { processDatasheetReadings } from "../submitHandlers";
import ResourceModel from "../../../trainingResources/resourceModel";
import { ro } from "date-fns/locale";
import ExportImportData from "./exportImportData";

export default function ObservedReadings(props) {
  const { datasheetId, isPrinting } = props;
  const datasheetContextValue = useContext(DatasheetContext);
  const {
    state: {
      datasheetDetails,
      datasheetStaticReadings,
      staticReadingRows,
      staticTables,
      certificateStaticTables,
      readingsGroupByDetails,
      standardRanges,
      unitSymbols,
      indicators,
      indicators_complianceStatus,
      cmcDetails,
      selectedSupportiveRanges,
      supportiveStandardMasterArray,
      settings,
      currentOperationState,
      DatasheetReadingsAdditionalInfo,
      instrumentDetails,
      standardMasterArray,
      selectedMastersIds,
    },
    dispatch,
  } = datasheetContextValue;

  const [readingRowsLocal, setReadingRowsLocal] = useState([]);
  const [selectedRangeIds, setSelectedRangeIds] = useState([]);
  const [derivedColumns, setDerivedColumns] = React.useState([]);
  const [tableConfig, setTableConfig] = React.useState({});
  const [deletedIds, setDeletedIds] = useState([]);
  const [activeRowIndex, setActiveRowIndex] = useState(-1);
  const [activeTable, setActiveTable] = useState(-1);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [jobNumbers, setJobNumbers] = useState([]);
  const [selectedDatasheetID, setSelectedselectedDatasheetID] = useState(null);
  const [selectedMasterORNot, setSelectedMAsterORNot] = useState(false);
  const [instructionModal, setInstructionModal] = React.useState(false);
  const [instructionInfo, setInstructionInfo] = React.useState("");
  const [editingRowIndex, setEditingRowIndex] = React.useState(17);

  // Resource Modal state
  const [openModalResource, setOpenModalResource] = React.useState(false);
  const [modalResourceUrl, setModalResourceUrl] = React.useState("");
  const handleOpenModalResource = (url) => {
    setModalResourceUrl(url);
    setOpenModalResource(true);
  };
  const handleCloseModalResource = () => {
    setOpenModalResource(false);
    setModalResourceUrl("");
  };

  // tableInstructionsmodal
  const renderInstruntionModal = () => {
    return (
      <Modal
        open={instructionModal}
        onClose={() => {
          setInstructionModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={{ ...multiValueModalStyle, background: "white" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: "5px",
            }}
          >
            <h4>Instructions</h4>
            <Button
              variant="contained"
              size="small"
              onClick={() => setInstructionModal(false)}
            >
              X
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <pre>
              <h6>{instructionInfo}</h6>
            </pre>
          </Box>
        </Box>
      </Modal>
    );
  };

  // utility functions
  const getCustomColumnCount = (
    tableId,
    isStatic = null,
    isCertificate = null,
    tables = null
  ) => {
    let tbl = tables;
    if (!tbl) {
      tbl =
        isStatic === "static"
          ? isCertificate === "certificate"
            ? certificateStaticTables
            : staticTables
          : null;
    }

    let count = 0;
    if (!tbl) return count;
    for (let i = 0; i < tbl.length; i++) {
      if (tbl[i].id == tableId) {
        for (let j = 0; j < MAX_COLS; j++) {
          if (tbl[i]["c" + (j + 1)] != null) {
            count++;
          } else {
            break;
          }
        }
      }
    }
    return count;
  };

  const getGroupedReadingDetails = (table) => {
    let rangeCol = table["defaultConfiguration"]["rangeCol"];
    if (!rangeCol) return undefined;

    let groupByUnit = {};
    let groupByHeader = {}
    let tmp = [];
    let currentUnit = null;
    let currentHeader = "undefined";
    let rowIds = []

    readingRowsLocal.forEach((row, rowIndex) => {
      if (row?.tableId === table.id) {
        let value = row[rangeCol];
        if (value && value !== "NA") {
          value = value.replaceAll("$", "");

          if (row['c1']?.includes("_rh_")) {
            groupByUnit[currentUnit] = [...(groupByUnit[currentUnit] || []), ...tmp]
            Object.keys(groupByUnit).forEach(unit => {
              groupByHeader[currentHeader] = {
                ...(groupByHeader[currentHeader]),
                [unit]: [
                  ...(groupByHeader[currentHeader]?.[unit] || []),
                  ...groupByUnit[unit]
                ]
              }
            })
            groupByHeader[currentHeader]['rowIds'] = [...(groupByHeader[currentHeader]['rowIds'] || []), ...rowIds]

            tmp = []
            groupByUnit = {}
            rowIds = []
            currentHeader = readingsGroupByDetails?.valuesPerRow[rowIndex] || "undefined"

          } else if (value?.includes("_unit_")) {
            let newUnit = value.split("_unit_")[1]
            if (currentUnit && newUnit != currentUnit) {
              groupByUnit[currentUnit] = [...(groupByUnit[currentUnit] || []), ...tmp]
              tmp = []
            }
            currentUnit = newUnit
          } else {
            rowIds.push(row.id)
            value = Number(value);
            if (isNaN(value)) return;
            tmp.push(value);
          }
        }
      }
    });
    groupByUnit[currentUnit] = [...(groupByHeader[currentHeader]?.[currentUnit] || []), ...tmp]
    Object.keys(groupByUnit).forEach(unit => {
      groupByHeader[currentHeader] = {
        ...(groupByHeader[currentHeader]),
        [unit]: [
          ...(groupByHeader[currentHeader]?.[unit] || []),
          ...groupByUnit[unit]
        ]
      }
    })
    groupByHeader[currentHeader]['rowIds'] = [...(groupByHeader[currentHeader]['rowIds'] || []), ...rowIds]

    // parse group by table name and columms
    let columns = table.defaultConfiguration.readingGroupByConfig?.split(",")
    let tableName = columns?.[0]?.split(".")[0]
    columns = columns?.map(column => column.split(".")[1])

    let groupedDsReadingDetails = {
      config: {
        tableName: tableName,
        columns: columns
      },
      rangeValues: groupByHeader
    }

    return groupedDsReadingDetails;
  };

  const checkMaster = (index) => {
    let rangeId = readingRowsLocal[index]["standardRanges"];
    rangeId =
      rangeId && rangeId !== "" && rangeId?.length > 0
        ? rangeId?.split(",")
        : [];
    rangeId = rangeId.filter((e) => e !== "");

    if (rangeId.length) return true;
    return false;
  };

  const validateSelectedStandardRange = (range, tableId) => {
    // 0. check is table is enabled for out of range validation
    if (
      !settings?.["DS Row: standard master validation"] ||
      settings?.["DS Row: standard master validation"] === "false"
    )
      return;

    // 1. validate LC precision count against standard least count
    let lc = range.lc?.split("#")[0];
    let std_lc = datasheetDetails.lc?.split("#")[0];

    // 2. calculate range lc precision count
    let lc_precision_count = 0;
    if (lc?.includes(".")) {
      lc_precision_count = lc.split(".")[1].length;
    }

    // 3. calculate standard lc precision count
    let std_lc_precision_count = 0;
    if (std_lc?.includes(".")) {
      std_lc_precision_count = std_lc.split(".")[1].length;
    }

    // 4. compare both precision count
    if (lc_precision_count < std_lc_precision_count) {
      dispatch({
        field: "indicators",
        value: {
          ...indicators,
          invalidLCPrecision: true,
        },
      });
    }
  };

  const updateSelectedStandardMasterIds = (selectedRangeIds, tableId) => {
    // 1. extract selected standard ids from selected range ids
    let selectedStandardIds = standardRanges.map((range) => {
      let status = false;
      selectedRangeIds
        ?.filter((item) => item)
        ?.forEach((rangeIds) => {
          rangeIds = rangeIds?.split(",").map((e) => e.split(":")[1]);
          if (rangeIds?.includes(String(range.rangeId)) === true) status = true;
        });
      if (status === true) {
        validateSelectedStandardRange(range, tableId);
        return range.id;
      } else return null;
    });

    // 2. update selected standard ids
    let _selectedStandardIds = selectedStandardIds.filter((e) => e > 0);
    _selectedStandardIds = [
      ...new Set([..._selectedStandardIds, ...selectedStandardIds]),
    ];

    dispatch({
      field: "selectedStandardIds",
      value: [..._selectedStandardIds],
    });
  };

  const initiateStaticReadingRows = async () => {
    // 1. if no readings then quit
    if (!datasheetStaticReadings) return null;

    // 2. config wiht default values
    let _selectedRangeIds = selectedRangeIds;
    let supportiveRanges = [];
    let rowUnitMap = {};
    let CurrentUnitRow = -1;

    let tableMap = {};
    staticTables?.map((s) => (tableMap[s.id] = s));

    /*
      3. prepare reading rows
  
        reading row structure:
        tableId, datasheetId, <col1 to col n>, uncertainty, standardRanges, rowId
      */
    let updatedDatasheetStaticReadings = [];
    for (let index = 0; index < datasheetStaticReadings?.length; index++) {
      let reading = datasheetStaticReadings[index];
      if (!rowUnitMap[reading.tableId]) {
        rowUnitMap[reading.tableId] = {};
      }
      if (reading["c1"]?.includes("_unit_")) {
        CurrentUnitRow = [reading.id, reading.uncertainty];
      } else {
        rowUnitMap[reading.tableId][reading.id] = CurrentUnitRow;
      }

      for (let i = 0; i < MAX_COLS; i++) {
        let value = reading["c" + (i + 1)];
        if (value != null) {
          if (!value.includes("_unit_") && !value.includes("_rh_")) {
            if (value === "NA") {
              value = "NA";
            } else {
              let prefix = `${derivedColumns[reading.tableId]?.includes("c" + (i + 1)) ||
                tableMap[reading.tableId]?.[`c${i + 1}`]?.includes(
                  UNCERTAINTY_LINK
                )
                ? "$"
                : ""
                }`;
              value = value.replaceAll("$", "");
              value = prefix + String(value).split("#")?.[0];
            }
          }
          reading["c" + (i + 1)] = value;
        } else {
          break;
        }
      }

      // remove only extra table columns having null values
      reading = Object.fromEntries(
        Object.entries(reading).filter(
          ([key, value]) => !/^c\d+$/.test(key) || value !== null
        )
      );

      // set readonly $ for uncertainty
      if (
        !reading?.uncertainty?.includes("_unit_") &&
        !reading?.uncertainty?.includes("_rh_")
      ) {
        reading["uncertainty"] = (
          "$" + String(reading?.uncertainty).split("#")?.[0]
        ).replace(/\$+/g, "$");
      }

      supportiveRanges.push(reading.supportiveRanges);

      // set current ranges to selectedRangeIds
      _selectedRangeIds[index] = reading.standardRanges;

      updatedDatasheetStaticReadings.push(reading);
    }

    setSelectedRangeIds(_selectedRangeIds);

    dispatch({
      field: "selectedSupportiveRanges",
      value: [...supportiveRanges],
    });


    // re process datasheet reading only after uncertainty caluclation
    if (currentOperationState == 1) {
      let res = await processDatasheetReadings(datasheetContextValue, [
        ...updatedDatasheetStaticReadings,
      ]);
      if (res) {
        dispatch({
          field: "staticReadingRows",
          value: [...res],
        });

        res = checkComplianceStatus(res)
        setReadingRowsLocal([...res]);
      }
    } else {
      setReadingRowsLocal([...updatedDatasheetStaticReadings]);
      dispatch({
        field: "staticReadingRows",
        value: [...updatedDatasheetStaticReadings],
      });
    }

  };

  const addStaticReadingRow = (tableId, rowIndex = null, type = null) => {
    // 1. get config data
    let newReadingRows = [...readingRowsLocal];
    let totalColumns = getCustomColumnCount(tableId, "static");

    let rows = [
      {
        tableId: tableId,
        datasheetId: Number(datasheetId),
        uncertainty: "$",
        standardRanges: null,
        id: 0,
      },
    ];
    let tableMap = {};
    staticTables?.map((s) => (tableMap[s.id] = s));

    // 2. init row with initial values
    for (let i = 0; i < totalColumns; i++) {
      rows[0][`c${i + 1}`] =
        derivedColumns[tableId].includes(`c${i + 1}`) ||
          tableMap[tableId][`c${i + 1}`]?.includes(UNCERTAINTY_LINK)
          ? "$"
          : "";
    }

    // 3. set default value to rows if any
    let tmpRows = [];
    if (
      tableConfig[tableId].defaultValues &&
      Object.keys(tableConfig[tableId].defaultValues).length
    ) {
      Object.keys(tableConfig[tableId].defaultValues).forEach((col, index) => {
        let values = (tableConfig[tableId].defaultValues[col] || "")
          .split(",")
          .map((c) => c.trim());
        for (let i = 0; i < values.length; i++) {
          if (tmpRows.length < i + 1) {
            tmpRows.push({ ...rows[0] });
          }
          tmpRows[i][col] = `$${values[i]}`;
        }
      });
      rows = [...tmpRows];
    }

    // 4. copy row (only selected standards)
    if (type === "copy") {
      rows[0]["standardRanges"] = readingRowsLocal[rowIndex]["standardRanges"];
      let supportiveRanges = [...selectedSupportiveRanges];
      supportiveRanges.push(supportiveRanges[rowIndex]);
      dispatch({
        field: "selectedSupportiveRanges",
        value: [...supportiveRanges],
      });
    }

    newReadingRows = newReadingRows.concat([...rows]);
    setReadingRowsLocal([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const addStaticUnitRow = (tableId) => {
    let newReadingRows = [...readingRowsLocal];
    let totalColumns = getCustomColumnCount(tableId, "static");

    let row = {
      tableId: tableId,
      datasheetId: Number(datasheetId),
      ...[...Array(totalColumns)].reduce((acc, _, i) => {
        acc[`c${i + 1}`] = "_unit_";
        return acc;
      }, {}),
      standardRanges: null,
      id: 0,
    };
    if (tableConfig[tableId].isUncertainty) {
      row["uncertainty"] = "_unit_";
    } else {
      row["uncertainty"] = null;
    }
    newReadingRows.push(row);
    setReadingRowsLocal([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const addStaticRowHeader = (tableId) => {
    let newReadingRows = [...readingRowsLocal];
    let totalColumns = getCustomColumnCount(tableId, "static");

    let row = {
      tableId: tableId,
      datasheetId: Number(datasheetId),
      ...[...Array(totalColumns)].reduce((acc, _, i) => {
        acc[`c${i + 1}`] = "_rh_";
        return acc;
      }, {}),
      standardRanges: null,
      id: 0,
    };
    if (tableConfig[tableId].isUncertainty) {
      row["uncertainty"] = "_rh_";
    }
    newReadingRows.push(row);
    setReadingRowsLocal([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const deleteStaticReadingRow = (rowIndex, tableId) => {
    let newReadingRows = [...readingRowsLocal];
    if (newReadingRows[rowIndex]['id'])
      Promise.all([
        axiosWithToken.delete(
          BASE_URL +
          `datasheetStaticReadings/${newReadingRows[rowIndex]['id']
          }`,
          { status: -1 }
        )
      ])
        .then((res) => {
          toast.success("Row deleted successfully!");
        })
        .catch((err) => {
          if (err.message !== "request_aborted") {
            toast.error("Failed to delete row, error:" + err);
          }
        });

    setDeletedIds([
      ...deletedIds,
      newReadingRows[rowIndex]['id'],
    ]);

    newReadingRows.splice(rowIndex, 1);
    setReadingRowsLocal([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });

    const remainingAdditionalInfo = DatasheetReadingsAdditionalInfo?.filter(
      (item) => item?.rowIndex !== rowIndex || item?.tableId !== tableId
    );

    dispatch({
      field: "DatasheetReadingsAdditionalInfo",
      value: remainingAdditionalInfo,
    });
  };

  const updateStaticCellValue = async (rowIndex, column, value) => {
    let newReadingRows = [...readingRowsLocal];
    if (value.includes("_unit_")) {
      newReadingRows[rowIndex][column] = value;

      let tmpRow = newReadingRows[rowIndex];
      Object.keys(tmpRow).forEach((key) => {
        if (/^c\d+$/.test(key)) {
          // Regex to match 'c' followed by any number
          tmpRow[key] =
            tmpRow[key]?.replaceAll("_unit_", "")?.length === 0
              ? value
              : tmpRow[key];
        }
      });
      tmpRow['uncertainty'] = tmpRow['uncertainty']?.replaceAll("_unit_", "")?.length === 0
        ? value
        : tmpRow['uncertainty'];
      newReadingRows[rowIndex] = { ...tmpRow };
    } else {
      newReadingRows[rowIndex][column] = value;
    }

    setReadingRowsLocal([...newReadingRows]);
    // dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
    setActiveRowIndex(-1);
    setActiveTable(-1);
    if (currentOperationState) {
      dispatch({
        field: "currentOperationState",
        value: 0,
      });
    }
  };

  const updateGroupByValue = (rowIndex, value) => {
    dispatch({
      field: "readingsGroupByDetails", value: {
        ...(readingsGroupByDetails || {}),
        valuesPerRow: {
          ...readingsGroupByDetails?.valuesPerRow,
          [rowIndex]: value
        }
      }
    });

  }

  const updateStandardRangesOfRow = (value, index) => {
    if (index === -1) {
      let _selectedSupportiveRanges = [...selectedSupportiveRanges];
      _selectedSupportiveRanges[activeRowIndex] = value;
      dispatch({
        field: "selectedSupportiveRanges",
        value: [..._selectedSupportiveRanges],
      });
      return;
    }

    let _staticReadingRows = [...readingRowsLocal];
    // TODO: correct below logic, as it is highly coupled to index which it should not at all !!!
    let rangeIds =
      _staticReadingRows[activeRowIndex][
      "standardRanges"
      ];
    rangeIds = String(rangeIds || "").split(",");

    rangeIds[index - 1] = value;

    _staticReadingRows[activeRowIndex][
      "standardRanges"
    ] = rangeIds.join(",");

    setReadingRowsLocal([..._staticReadingRows]);
    dispatch({ field: "staticReadingRows", value: [..._staticReadingRows] });

    let _selectedRangeIds = selectedRangeIds;
    _selectedRangeIds[activeRowIndex] = rangeIds.join(",");
    setSelectedRangeIds(_selectedRangeIds);

    let tableId = _staticReadingRows[activeRowIndex][0];
    updateSelectedStandardMasterIds(_selectedRangeIds, tableId);
  };

  const deleteTable = (table) => {
    // clear readings alert
    const readingExists = readingRowsLocal?.some(
      (reading) => reading?.tableId == table?.id
    );

    if (readingExists) {
      window.alert(
        `Please clear readings before deleting the ${table?.tableName} table.`
      );
      return;
    }
    // table delte alert
    const confirmed = window.confirm(
      `Are you sure you want to delete the ${table?.tableName} table?`
    );

    if (!confirmed) return; // Exit if not confirmed

    // append into previoud ids
    let data = {
      deletedTableIds:
        (datasheetDetails?.deletedTableIds
          ? datasheetDetails?.deletedTableIds + ","
          : "") + table?.id,
    };
    Promise.all([
      axiosWithToken
        .patch(BASE_URL + `datasheets/${datasheetId}`, data)
        .then((res) => { })
        .catch((err) => {
          console.error(err);
        }),
      axiosWithToken
        .patch(BASE_URL + `certificates/${datasheetId}`, data)
        .then((res) => { })
        .catch((err) => {
          console.error(err);
        }),
    ])
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.error(`[ERROR] Failed to delete table, error: ${err}`);
      });
  };

  useEffect(() => {
    if (datasheetDetails?.instrumentId < 1) return;

    fetchDatasheetStaticTables(
      datasheetDetails.instrumentId,
      datasheetDetails.deletedTableIds, //TODO: pass static tables deleted ids only
      setDerivedColumns,
      setTableConfig,
      dispatch
    );
    fetchCertificateStaticTables(
      datasheetDetails.instrumentId,
      datasheetDetails.deletedTableIds, // considered as certificated table id as both have same table ids
      dispatch
    );
  }, [datasheetDetails.instrumentId, datasheetDetails.deletedTableIds]);

  useEffect(() => {
    if (standardRanges) fetchStaticReadingRows(datasheetId, dispatch);
  }, [standardRanges]);

  useEffect(() => {
    if (datasheetStaticReadings?.length > 0) {
      initiateStaticReadingRows();
    }
  }, [datasheetStaticReadings]);

  useEffect(() => {
    if (staticReadingRows != readingRowsLocal) {
      setReadingRowsLocal([...staticReadingRows]);
    }
  }, [staticReadingRows]);

  useEffect(() => {
    // Fetch job numbers and update the state
    const getJobNumbers = async () => {
      const fetchedJobNumbers = await fetchbeforeAutoloadJobNumber(datasheetId, datasheetDetails);
      setJobNumbers(fetchedJobNumbers);
    };

    getJobNumbers();
  }, [datasheetId, datasheetDetails]);

  // render block
  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null;
    let hasCommonHeader = defaultConfiguration.hasCommonHeader;
    if (hasCommonHeader && defaultConfiguration.commonHeaderConfig) {
      let commonHeaderConfig =
        defaultConfiguration.commonHeaderConfig?.split(",");
      let cols = [];
      commonHeaderConfig.forEach((col, index) => {
        let [name, size] = col?.split(":");
        let classNames =
          "header-border " + (name != "$" ? "header-border-bottom" : "");
        cols.push(
          <TableCell
            key={"common-header-" + index}
            className={!isPrinting ? classNames : ""}
            align="center"
            style={{ textAlign: "center" }}
            colSpan={Number(size)}
          >
            {name != "$" ? name : ""}
          </TableCell>
        );
      });

      return (
        <TableRow>
          {/* for SN */}
          {!isPrinting && (
            <>
              <TableCell
                className={isPrinting ? "header-border-print" : "header-border"}
                align="center"
              ></TableCell>
              <TableCell
                className={isPrinting ? "header-border-print" : "header-border"}
                align="center"
              ></TableCell>
            </>
          )}
          {cols}
        </TableRow>
      );
    }
    return null;
  };

  const renderUnit = (value, onChange, renderMode) => {
    if (renderMode == "editMode") {
      return (
        <div id="datasheet_editdatasheet_readingrow_selectunit">
          {isPrinting ? (
            <Typography variant="p">{value}</Typography>
          ) : (
            <select name="units" id="units" onChange={onChange} value={value}>
              <option value="">Select Unit</option>
              {unitSymbols?.map((unit) => {
                return (
                  <option value={unit} key={unit}>
                    {unit}
                  </option>
                );
              })}
            </select>
          )}
        </div>
      );
    } else {
      return (
        <Typography variant="p">
          {unitSymbols?.includes(value) ? value : ""}
        </Typography>
      );
    }
  };

  const getStandardRangeLC = (
    standardRangeId,
    stdRangePref,
    isRange,
    tableIndex
  ) => {
    standardRangeId = standardRangeId?.split(",").map((e) => e.split(":")[1])[
      stdRangePref > 0 ? stdRangePref - 1 : 0
    ];

    let range = standardRanges?.filter(
      (range) => range.rangeId === Number(standardRangeId)
    );
    let duc_ranges;
    if (isRange) {
      duc_ranges = datasheetDetails?.ranges?.split("||");
      if (duc_ranges?.length > 1) {
        duc_ranges = duc_ranges[tableIndex || 0];
      } else duc_ranges = duc_ranges[0];
      range = range?.[0]?.range
        ? Math.max(
          ...(range[0]?.range
            .split("|")
            .map((e) => Number(e.split("#")[0]))
            .filter((e) => !isNaN(e)) || [])
        )
        : null;

      duc_ranges =
        duc_ranges !== undefined && duc_ranges !== null
          ? Math.max(
            ...(duc_ranges
              .split("|")
              .map((e) => Number(e.split("#")[0]))
              .filter((e) => !isNaN(e)) || [])
          )
          : null;

      if (range && range !== null && duc_ranges && duc_ranges !== null)
        range = Math.min(range, duc_ranges);
      else if (!range && duc_ranges) range = duc_ranges;
    }

    return isRange ? range : range?.[0]?.lc?.split("#")?.[0];
  };

  const getConditionalFormattedColor = (
    value,
    cellKey,
    table,
    tableIndex,
    rowIndex
  ) => {
    value = `${value}`.replaceAll("$", "");
    let { relations } = JSON.parse(table?.conditionFormatting || "{}");
    let dsCol;
    let conditions = relations[cellKey];
    if (conditions) {
      conditions = Array.isArray(conditions) ? conditions : [conditions]
      for (let i = 0; i < conditions?.length; i++) {
        let conditionDetails = conditions[i]
        let _dsCol = conditionDetails.dsCol.split(".");
        dsCol = !String(_dsCol[0])?.includes("ds")
          ? Number(_dsCol[0])
          : _dsCol[0];
        let stdRangePref = Number(_dsCol[1] || "0");

        let valToCompare = 0;
        if (String(dsCol)?.includes("ds")) {
          _dsCol = dsCol.split("ds")[1];
          valToCompare = readingRowsLocal[rowIndex]['c' + _dsCol];
        } else {
          let possibleValues = [
            0,
            getStandardRangeLC(
              readingRowsLocal[rowIndex]["standardRanges"],
              stdRangePref,
              true,
              tableIndex
            ) || 0,
            datasheetDetails.lc,
            getStandardRangeLC(
              readingRowsLocal[rowIndex]["standardRanges"],
              stdRangePref
            ) || 0,
            datasheetDetails.accuracy,
            // add master lc

          ];
          valToCompare = possibleValues[dsCol - 1];
        }

        valToCompare = String(valToCompare)
          ?.replace("\n", "")
          .replaceAll("$", "")
          .split("||");

        valToCompare =
          valToCompare?.length > tableIndex
            ? valToCompare[tableIndex]
            : valToCompare[0];

        // remove unit if it contains
        valToCompare = valToCompare?.split("#")[0];

        if (
          conditionDetails.condition?.toLowerCase() === "lt" &&
          Number(dsCol == 2 ? value : Math.abs(value)) <
          Number(dsCol == 2 ? valToCompare : Math.abs(valToCompare))
        ) {
          return conditionDetails.color;
        } else if (
          conditionDetails.condition?.toLowerCase() === "gt" &&
          Number(dsCol == 2 ? value : Math.abs(value)) >
          Number(dsCol == 2 ? valToCompare : Math.abs(valToCompare))
        ) {
          return conditionDetails.color;
        }
        else if (
          conditionDetails.condition?.toLowerCase() === "nan" &&
          isNaN(value)
        ) {
          return conditionDetails.color;
        }
        else if (
          conditionDetails.condition?.toLowerCase() === "precisioncountnotequal"
        ) {
          value = value.toString();
          let valueDecimalCount = value.includes(".")
            ? value.split(".").pop().length
            : 0;
          valToCompare = valToCompare.toString();
          let valToCompareDecimalCount = 0;
          if (String(valToCompare).includes(".")) {
            valToCompareDecimalCount = String(valToCompare).split(".")[1];
            valToCompareDecimalCount =
              valToCompareDecimalCount.split("#")[0].length;
          }

          if (valueDecimalCount !== valToCompareDecimalCount) {
            return conditionDetails.color;
          }
        }
      }
    }

    return "";
  };

  const checkComplianceStatus = (readingRows) => {

    let complianceStatus = true;
    let rowWiseComplianceStatus = true;


    for (let rowIndex = 0; rowIndex < readingRows.length; rowIndex++) {
      let row = readingRows[rowIndex]

      readingRows[rowIndex]["complianceStatus"] = 1;

      if (row["c1"].includes("_unit_") || row["c1"].includes("_rh_")) {
        continue;
      }

      let table, tableIndex;
      for (let j = 0; j < staticTables.length; j++) {

        if (staticTables[j].id == row.tableId) {
          table = staticTables[j]
          tableIndex = j;
          break;
        }
      }

      // filter row to remove all key and values except c1, c2...cn
      let filteredRow = Object.fromEntries(
        Object.entries(row).filter(([key]) => /^c\d+$/.test(key))
      );

      if (table?.isUncertainty) {
        filteredRow["uncertainty"] = row["uncertainty"];
      }

      rowWiseComplianceStatus = true;
      Object.keys(filteredRow)?.forEach((cellKey) => {
        let color = getConditionalFormattedColor(filteredRow[cellKey], cellKey, table, tableIndex, rowIndex)
        if (color != "") {
          rowWiseComplianceStatus = false;
        }
      })

      if (!rowWiseComplianceStatus) {
        readingRows[rowIndex]["complianceStatus"] = 0
        complianceStatus = false;
      } else {
        readingRows[rowIndex]["complianceStatus"] = 1
      }
    }
    dispatch({
      field: "indicators_complianceStatus",
      value: complianceStatus,
    });

    return readingRows;
  }


  // TODO: critical: this function is defined only for file upload, rename if properly
  function updateAdditionalDatasheetReadings(
    table,
    DatasheetReadingsAdditionalInfo,
    cellKey,
    URL,
    rowIndex
  ) {
    let payload = {
      tableId: table?.id,
      datasheetReadingId: Number(datasheetId),
      rowIndex: rowIndex,
    };

    payload[cellKey] = JSON.stringify({ image: URL });

    let updatedDatasheetReadingsAdditionalInfo = [
      ...DatasheetReadingsAdditionalInfo,
    ]; // Clone the original array

    // Find if there's an object with the same tableId, datasheetReadingId, and rowIndex as the payload
    const existingEntryIndex =
      updatedDatasheetReadingsAdditionalInfo.findIndex(
        (entry) =>
          entry.tableId === payload.tableId &&
          entry.datasheetReadingId === payload.datasheetReadingId &&
          entry.rowIndex === payload.rowIndex
      );

    if (existingEntryIndex !== -1) {
      // If an object with the same tableId, datasheetReadingId, and rowIndex exists, update its c1 value
      updatedDatasheetReadingsAdditionalInfo[existingEntryIndex][
        cellKey
      ] = payload[cellKey];
    } else {
      // If not, create a new object and add it to the array
      const newEntry = {
        tableId: payload.tableId,
        datasheetReadingId: payload.datasheetReadingId,
        rowIndex: payload.rowIndex,
      };
      newEntry[cellKey] = payload[cellKey];

      updatedDatasheetReadingsAdditionalInfo.push(newEntry);
    }

    dispatch({
      field: "DatasheetReadingsAdditionalInfo",
      value: [...updatedDatasheetReadingsAdditionalInfo],
    });
  }

  // TODO: need to bring this function out of parent function
  const handleFileUpload = (event, rowIndex, cellKey, table) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);

    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then(async (res) => {
        if (res.status === 200) {
          await updateAdditionalDatasheetReadings(
            table,
            DatasheetReadingsAdditionalInfo,
            cellKey,
            res.data,
            rowIndex
          );
          toast.success("File Uploaded Successfully!");
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const renderTableCell = (
    table,
    tableIndex,
    rowIndex,
    cellValue,
    cellKey,
    onChange = {},
    renderMode
  ) => {
    let referenceImageUpload =
      table?.defaultConfiguration?.referenceImageUpload || "";

    let imageReferenceIndex = [];
    if (referenceImageUpload) {
      imageReferenceIndex = referenceImageUpload?.split(",") || []
    }

    const VisuallyHiddenInput = styled("input")`
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      white-space: nowrap;
      width: 1px;
    `;

    let field = null;
    // 1. it its unit field
    if (String(cellValue)?.includes("_unit_")) {
      //removed check to check to show text field instead of unit for m-x
      let value = cellValue.split("_unit_")[1];
      field = renderUnit(
        value,
        (e) => {
          updateStaticCellValue(
            rowIndex,
            cellKey,
            "_unit_" + e.target.value
          );
        },
        renderMode
      );
    } else if (renderMode === "viewMode") {
      field = (
        <span
          style={{
            color: getConditionalFormattedColor(
              cellValue,
              cellKey,
              table,
              tableIndex,
              rowIndex
            ),
          }}
        >
          <Typography variant="p">
            {String(cellValue).replaceAll("$", "")}
          </Typography>
        </span>
      );
    }
    // 2. if its read only value field
    else if (cellValue && cellValue[0] == "$") {
      field = (
        <span
          style={{
            color: getConditionalFormattedColor(
              cellValue,
              cellKey,
              table,
              tableIndex,
              rowIndex
            ),
          }}
        >
          {cellValue.slice(1)}
        </span>
      );
    } else if (cellValue == "null" && cellValue == null) {
      field = (
        <TextField
          size="small"
          value={""}
          InputProps={{
            style: {
              fontSize: "12px",
              margin: "0px",
              padding: "0px",
            },
            autoComplete: "off",
          }}
          onChange={(e) => {
            updateStaticCellValue(
              rowIndex,
              cellKey,
              e.target.value
            );
          }}
          variant="standard"
        ></TextField>
      );
    }

    // 3. if its a value input field
    else {
      const isImageUploadCell = imageReferenceIndex?.includes(cellKey);
      const matchingObjects = DatasheetReadingsAdditionalInfo?.filter(
        (obj) => obj.rowIndex === rowIndex && obj.tableId === table?.id
      )?.[0];

      // Check if the key exists in matchingObjects and if it has a value
      const hasValue = matchingObjects
        ? cellKey in matchingObjects &&
        matchingObjects[cellKey] !== undefined
        : false;

      field = (
        <>
          {isPrinting ? (
            <Typography variant="p">{cellValue}</Typography>
          ) : (
            <div style={{ display: "flex" }}>
              <TextField
                id="datasheet_editdatasheet_readingrow_enterReading"
                size="small"
                sx={{
                  input: {
                    color: getConditionalFormattedColor(
                      cellValue,
                      cellKey,
                      table,
                      tableIndex,
                      rowIndex
                    ),
                  },
                }}
                value={cellValue}
                InputProps={{
                  style: {
                    fontSize: "12px",
                    margin: "0px",
                    padding: "0px",
                  },
                  autoComplete: "off",
                }}
                onChange={async (e) => {
                  updateStaticCellValue(
                    rowIndex,
                    cellKey,
                    e.target.value
                  );
                }}
                variant="standard"
              ></TextField>
              {isImageUploadCell && (
                <IconButton
                  id="create_document_uploadfile"
                  component="label"
                  variant="contained"
                  key={DatasheetReadingsAdditionalInfo}
                >
                  <CloudUploadIcon
                    style={{
                      fontSize: "16px",
                      color: hasValue ? "#21b921" : "none",
                    }}
                  />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) =>
                      handleFileUpload(e, rowIndex, cellKey, table)
                    }
                  />
                </IconButton>
              )}
            </div>
          )}
        </>
      );
    }

    if (renderMode === "viewMode") {
      return (
        <TableCell
          key={rowIndex + cellKey}
          style={{
            padding: "4px",
          }}
          variant="standard"
          onClick={() => {
            setEditingRowIndex(rowIndex);
          }}
        >
          {field}
        </TableCell>
      );
    } else {
      return (
        <TableCell
          key={rowIndex + cellKey}
          style={{
            padding: "4px",
          }}
          variant="standard"
        >
          {field}
        </TableCell>
      );
    }
  };

  const renderActions = (
    rowIndex,
    tableId,
    tableIndex,
    table,
    hasActions = false
  ) => {
    const handleClose = () => {
      dispatch({
        field: "staticReadingRows",
        value: readingRowsLocal,
      });
      setOpenModal(false);
    };

    return (
      <ButtonGroup size="small" aria-label="small button group">
        {hasActions && (
          <>
            <Button
              id="datasheet_editdatasheet_readingrow_copy"
              onClick={(e) => {
                addStaticReadingRow(tableId, rowIndex, "copy");
              }}
            >
              <ContentCopyIcon />
            </Button>
            {isMobile && (
              <Button
                onClick={() => {
                  setOpenModal(true);
                  setSelectedRowIndex(rowIndex);
                }}
              >
                <PreviewIcon />
              </Button>
            )}
          </>
        )}

        <RowDetailsModal
          open={openModal}
          handleClose={handleClose}
          staticTables={staticTables}
          staticReadingRows={readingRowsLocal}
          tableIndex={tableIndex}
          rowIndex={selectedRowIndex}
          table={table}
          renderTableCell={renderTableCell}
          tableConfig={tableConfig}
          isPrinting={isPrinting}
        />
      </ButtonGroup>
    );
  };

  const renderGroupByInputField = (table, rowIndex) => {

    let options = []
    let processedvalues = []
    let counter = 1
    let referenceColumns = table?.defaultConfiguration?.readingGroupByConfig?.split(",")
    if (referenceColumns?.[0]?.includes('cmc.')) {
      referenceColumns = referenceColumns.map(column => column.replace("cmc.", ""))
      cmcDetails?.cmcReadings?.forEach(cmcReading => {
        let value = []
        referenceColumns.forEach(column => value.push(`${column}: ${cmcReading[column]}`))
        value = value.join(", ")
        if (!processedvalues?.includes(value)) {
          options.push(value);
          counter += 1
        }
        processedvalues.push(value)

      })
    }

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          value={readingsGroupByDetails?.valuesPerRow?.[rowIndex] || []}
          onChange={(event, newValue) => {
            updateGroupByValue(rowIndex, newValue);
          }}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Group By"
              variant="outlined"
              size="small"
            />
          )}
        />
      </FormControl>
    )
  }

  const renderReadingRow = (
    row,
    rowIndex,
    table,
    tableIndex,
    staticReadingRows,
    renderMode
  ) => {
    let _row = [];
    let rowType = "";
    if (row?.tableId == table.id) {
      if (String(row?.c1)?.includes("_rh_")) {
        rowType = "header";
        _row = (
          <TableRow key={"reading-row" + rowIndex} id="pagbreaksborder">
            {!isPrinting ? (
              <TableCell>
                {renderActions(rowIndex, table.id, tableIndex, table)}
              </TableCell>
            ) : null}
            <TableCell
              colSpan={
                table?.defaultConfiguration?.totalColumns +
                (table?.isUncertainty ? 2 : 0) -
                (isPrinting ? 1 : 0)
              }
            >
              {/* dynamically configure row header input fields */}
              {(() => {
                let rowHeaderSize = 12
                let groupBySize = table.defaultConfiguration.readingGroupByConfig?.length > 0 ? 7 : 0
                rowHeaderSize -= groupBySize
                let groupByHeaderInputField = groupBySize > 0 ?
                  <Grid item xs={groupBySize}>
                    {renderGroupByInputField(table, rowIndex)}
                  </Grid> : null
                return (
                  <div style={{ textAlign: "center" }}>
                    {isPrinting ? (
                      <span>
                        {row?.c1?.split("_rh_")?.[1] || ""}
                      </span>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={rowHeaderSize}>
                          <TextField
                            id="datasheet_editdatasheet_readingrow_enterrrowheading"
                            size="small"
                            fullWidth
                            value={row?.c1?.split("_rh_")[1]}
                            InputProps={{
                              style: {
                                fontSize: "14px",
                                margin: "auto",
                                padding: "0px",
                              },
                              autoComplete: "off",
                            }}
                            onChange={(e) => {
                              updateStaticCellValue(
                                rowIndex,
                                "c1",
                                "_rh_" + e.target.value
                              );
                            }}
                          ></TextField>
                        </Grid>
                        {groupByHeaderInputField}
                      </Grid>
                    )}
                  </div>)
              })()}

            </TableCell>

            {!isPrinting ? (
              <TableCell>
                {showComponent("delete") && (
                  <DeleteIcon
                    id="datasheet_editdatasheet_readingrowheader_delete"
                    style={{
                      color: "#dc3545",
                    }}
                    onClick={(e) => {
                      const confirmed = window.confirm(
                        "Are you sure you want to delete the row?"
                      );
                      if (confirmed) {
                        deleteStaticReadingRow(rowIndex);
                      }
                    }}
                  />
                )}
              </TableCell>
            ) : null}
          </TableRow>
        );
      } else if (String(row?.c1)?.includes("_unit_")) {
        rowType = "unit";
        _row = (
          <TableRow key={rowIndex} id="pagbreaksborder">
            {!isPrinting ? (
              <TableCell>
                {renderActions(rowIndex, table.id, tableIndex, table)}
              </TableCell>
            ) : null}
            {!isPrinting && <TableCell></TableCell>}
            {(() => {
              // filter row to remove all key and values except c1, c2...cn
              let filteredRow = Object.fromEntries(
                Object.entries(row).filter(([key]) => /^c\d+$/.test(key))
              );

              if (table?.isUncertainty) {
                filteredRow["uncertainty"] = row["uncertainty"];
              }

              return Object.keys(filteredRow)?.map((key, cellIndex) => {
                let cellValue = filteredRow[key];
                return renderTableCell(
                  table,
                  tableIndex,
                  rowIndex,
                  cellValue,
                  key,
                  null,
                  renderMode
                );
              });
            })()}
            {!isPrinting && (
              <TableCell>
                {showComponent("delete") && (
                  <DeleteIcon
                    id="datasheet_editdatasheet_readingrowunit_delete"
                    style={{
                      color: "#dc3545",
                    }}
                    onClick={(e) => {
                      const confirmed = window.confirm(
                        "Are you sure you want to delete the row?"
                      );
                      if (confirmed) {
                        deleteStaticReadingRow(rowIndex);
                      }
                    }}
                  />
                )}
              </TableCell>
            )}
          </TableRow>
        );
      } else {
        rowType = "data";
        _row = (
          <TableRow key={rowIndex} id="pagbreaksborder">
            {!isPrinting ? (
              <TableCell>
                {renderActions(rowIndex, table.id, tableIndex, table, true)}
              </TableCell>
            ) : null}
            {!isPrinting ? (
              <TableCell>
                <button
                  id="datasheet_editdatasheet_readingrow_selectmaster"
                  style={{
                    color: checkMaster(rowIndex) ? "green" : "red",
                    rotate:
                      table.id === activeTable && rowIndex === activeRowIndex
                        ? "180deg"
                        : "0deg",
                  }}
                  onClick={() => {
                    if (activeTable === -1 || activeRowIndex === -1) {
                      setActiveRowIndex(rowIndex);
                      setActiveTable(table.id);
                    } else if (
                      activeTable === table.id &&
                      activeRowIndex === rowIndex
                    ) {
                      setActiveRowIndex(-1);
                      setActiveTable(-1);
                    } else {
                      setActiveRowIndex(rowIndex);
                      setActiveTable(table.id);
                    }
                  }}
                >
                  <ExpandCircleDownOutlined />
                </button>
              </TableCell>
            ) : null}
            {(() => {
              // filter row to remove all key and values except c1, c2...cn
              let filteredRow = Object.fromEntries(
                Object.entries(row).filter(([key]) => /^c\d+$/.test(key))
              );

              if (table?.isUncertainty) {
                filteredRow["uncertainty"] = row["uncertainty"];
              }

              return Object.keys(filteredRow)?.map((key, cellIndex) => {
                let cellValue = filteredRow[key];
                return renderTableCell(
                  table,
                  tableIndex,
                  rowIndex,
                  cellValue,
                  key,
                  null,
                  renderMode
                );
              });
            })()}

            {!isPrinting ? (
              <TableCell>
                {showComponent("delete") && (
                  <DeleteIcon
                    id="datasheet_editdatasheet_readingrow_delete"
                    style={{
                      color: "#dc3545",
                    }}
                    onClick={(e) => {
                      const confirmed = window.confirm(
                        "Are you sure you want to delete the row?"
                      );
                      if (confirmed) {
                        deleteStaticReadingRow(rowIndex);
                      }
                    }}
                  />
                )}
              </TableCell>
            ) : null}
          </TableRow>
        );
      }
    }
    if (
      activeTable === table.id &&
      rowIndex == activeRowIndex &&
      rowType == "data"
    ) {
      let standardRow = (
        <tr id="datasheet_editdatasheet_readingrow_selectmasterrow">
          <td></td>
          <td></td>
          <TableCell
            colSpan={
              table?.defaultConfiguration?.totalColumns +
              (table?.isUncertainty ? 1 : 0)
            }
          >
            {" "}
            <StandardMasterSelection
              index={0}
              label="Standard master 1"
              standardRanges={standardRanges}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              valId={
                (
                  (
                    staticReadingRows[activeRowIndex][
                    "standardRanges"
                    ] || ""
                  ).split(",")[0] || ""
                ).split(":")[1] || ""
              }
            />
            <br />
            <StandardMasterSelection
              index={1}
              label="Standard master 2"
              standardRanges={standardRanges}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              valId={
                (
                  (
                    staticReadingRows[activeRowIndex][
                    "standardRanges"
                    ] || ""
                  ).split(",")[1] || ""
                ).split(":")[1] || ""
              }
            />
            <br />
            <StandardMasterSelection
              index={2}
              label="Standard master 3"
              standardRanges={standardRanges}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              valId={
                (
                  (
                    staticReadingRows[activeRowIndex][
                    "standardRanges"
                    ] || ""
                  ).split(",")[2] || ""
                ).split(":")[1] || ""
              }
            />
            <br />
            <StandardMasterSelection
              index={3}
              label="Standard master 4"
              standardRanges={standardRanges}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              valId={
                (
                  (
                    staticReadingRows[activeRowIndex][
                    "standardRanges"
                    ] || ""
                  ).split(",")[3] || ""
                ).split(":")[1] || ""
              }
            />
            <br />
            <StandardMasterSelection
              index={4}
              label="Standard master 5"
              standardRanges={standardRanges}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              valId={
                (
                  (
                    staticReadingRows[activeRowIndex][
                    "standardRanges"
                    ] || ""
                  ).split(",")[4] || ""
                ).split(":")[1] || ""
              }
            />
            <br />
            <StandardMasterSelection
              index={5}
              label="Standard master 6"
              standardRanges={standardRanges}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              valId={
                (
                  (
                    staticReadingRows[activeRowIndex][
                    "standardRanges"
                    ] || ""
                  ).split(",")[5] || ""
                ).split(":")[1] || ""
              }
            />
            <br />



            <StandardMasterSelection
              index={6}
              label="Standard master 7"
              standardRanges={standardRanges}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              valId={
                (
                  (
                    staticReadingRows[activeRowIndex][
                    "standardRanges"
                    ] || ""
                  ).split(",")[6] || ""
                ).split(":")[1] || ""
              }
            />
            <br />
            <StandardMasterSelection
              index={7}
              label="Standard master 8"
              standardRanges={standardRanges}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              valId={
                (
                  (
                    staticReadingRows[activeRowIndex][
                    "standardRanges"
                    ] || ""
                  ).split(",")[7] || ""
                ).split(":")[1] || ""
              }
            />
            <br />
            <StandardMasterSelection
              index={8}
              label="Standard master 9"
              standardRanges={standardRanges}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              valId={
                (
                  (
                    staticReadingRows[activeRowIndex][
                    "standardRanges"
                    ] || ""
                  ).split(",")[8] || ""
                ).split(":")[1] || ""
              }
            />
            <br />

            <StandardMasterSelection
              index={4}
              label="Supportive master"
              standardRanges={supportiveStandardMasterArray}
              updateStandardRangesOfRow={updateStandardRangesOfRow}
              rowIndex={rowIndex}
              table={table}
              isSupportive={true}
              valId={
                (
                  (selectedSupportiveRanges[activeRowIndex] || "").split(
                    ","
                  )[0] || ""
                ).split(":")[1] || ""
              }
            />
          </TableCell>
        </tr>
      );
      return [_row, standardRow];
    } else {
      return [_row];
    }
  };

  const renderRows = (table, tableIndex, staticReadingRows, range) => {
    let rows = [];
    staticReadingRows?.forEach((row, rowIndex) => {
      editingRowIndex !== rowIndex &&
        rows.push(
          ...renderReadingRow(
            row,
            rowIndex,
            table,
            tableIndex,
            staticReadingRows,
            "viewMode"
          )
        );

      editingRowIndex === rowIndex &&
        rows.push(
          ...renderReadingRow(
            row,
            rowIndex,
            table,
            tableIndex,
            staticReadingRows,
            "editMode"
          )
        );
    });

    return rows;
  };

  const renderReadingTables = () => {
    if (!staticTables) return <Box sx={{ width: "100%" }}>Loading...</Box>;
    if (staticTables.length < 1)
      return <Box sx={{ width: "100%" }}>No tables found!</Box>;
    let EditandViewTable = isPrinting ? ClassicTableDatasheet : ClassicTable;

    let range = 0;
    return staticTables?.map((table, tableIndex) => {
      if (datasheetDetails.tableDisplayStatus === 1 && table.isAutomated !== 0)
        return null;
      else if (
        datasheetDetails.tableDisplayStatus === 2 &&
        table.isAutomated !== 1
      )
        return null;

      range = 0;
      return (
        <div
          key={tableIndex}
          sx={{ mb: 5 }}
          style={{
            marginBottom: !isPrinting ? "60px" : "10px",
            pageBreakInside: tableIndex === 0 ? "auto" : "avoid",
          }}
        >
          {!isPrinting ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" }} >
              <Typography
                variant="h6"
                component="div"
                style={{ float: "left" }}
              >
                {tableIndex + 1 + ". "}
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="standard"
                  type="text"
                  inputProps={{
                    autoComplete: "off",
                  }}
                  value={
                    datasheetDetails?.configuration?.customTableNames?.[
                    table.id
                    ] || table.tableName
                  }
                  onChange={(e) => {
                    dispatch({
                      field: "datasheetDetails",
                      value: {
                        ...datasheetDetails,
                        configuration: {
                          ...datasheetDetails.configuration,
                          customTableNames: {
                            ...datasheetDetails.configuration.customTableNames,
                            [table.id]: e.target.value,
                          },
                        },
                      },
                    });
                  }}
                />
                <Button size="sm">
                  <InfoIcon
                    id="datasheet_editdatasheet_readingtablehead_info"
                    style={{
                      color: "#8d7a7c",
                    }}
                    onClick={(e) => {
                      setInstructionModal(true);
                      setInstructionInfo(table.tableInstructions);
                    }}
                  />
                </Button>
                {showComponent("delete") ? "|" : ""}
                {showComponent("delete") && (
                  <Button size="sm">
                    <DeleteIcon
                      id="datasheet_editdatasheet_readingtablehead_delete"
                      style={{
                        color: "#8d7a7c",
                      }}
                      onClick={() => deleteTable(table)}
                    />
                  </Button>
                )}
              </Typography>

              <Typography
                variant="h6"
                component="div"
                style={{ float: "right" }}
              >
                <ExportImportData
                  tableId={table.id}
                  tableName={datasheetDetails?.configuration?.customTableNames?.[table.id] || table.tableName}
                  datasheetId={datasheetId}
                  tableIndex={tableIndex}
                  setReadingRowsLocal={setReadingRowsLocal}
                />
              </Typography>
            </div>
          ) : (
            <Typography variant="body1" component="div">
              {datasheetDetails?.configuration?.customTableNames?.[table.id] ||
                table.tableName}
            </Typography>
          )}
          <div style={{ width: "100%", overflow: "auto" }}>
            <EditandViewTable>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  {renderCommonHeader(table?.defaultConfiguration)}
                  <TableRow>
                    {!isPrinting ? (
                      <>
                        <TableCell>
                          {table?.isUncertainty ? (
                            <Button
                              id="datasheet_editdatasheet_readingtablehead_expanded_uncertainty"
                              variant="outlined"
                              size="small"
                              style={{
                                fontSize: "12px",
                                width: "100px",
                              }}
                              onClick={() => {
                                let groupedReadingRangeValues =
                                  getGroupedReadingDetails(table);
                                sessionStorage.setItem('groupedDsReadingDetails', JSON.stringify(groupedReadingRangeValues))
                                window.location.href = `/#/uncertainty/${datasheetId}/${datasheetDetails.instrumentId}/1/${table.id}`;
                              }}
                            >
                              Uncertainty Report
                            </Button>
                          ) : null}
                        </TableCell>
                        <TableCell
                          className="header-border"
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          <Typography noWrap style={{ fontSize: "12px" }}>
                            Select Master
                          </Typography>
                        </TableCell>
                      </>
                    ) : null}
                    {/* render columns*/}
                    {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                      if (table["c" + i]?.toLowerCase()?.match(/m+\d+/g)) {
                        range++;
                      }
                      if (table["c" + i] != null) {
                        return (
                          <TableCell
                            key={"static-table-column" + i}
                            className={
                              isPrinting
                                ? "header-border-print"
                                : "header-border"
                            }
                            align="center"
                            style={{ textAlign: "center" }}
                          >
                            <div>
                              <Typography
                                // noWrap
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                {table["c" + i]?.includes(UNCERTAINTY_LINK)
                                  ? table["c" + i]?.split(UNCERTAINTY_LINK)?.[0]
                                  : table["c" + i]?.includes(EXTRA_COLUMN_LINK)
                                    ? table["c" + i]?.split(
                                      EXTRA_COLUMN_LINK
                                    )?.[0]
                                    : table["c" + i]}
                              </Typography>
                            </div>
                          </TableCell>
                        );
                      }
                    })}
                    {tableConfig[table.id]?.isUncertainty ? (
                      <TableCell>
                        <Typography
                          noWrap
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Uncertainty (±)
                        </Typography>
                      </TableCell>
                    ) : null}
                    {!isPrinting ? (
                      <TableCell
                        className={
                          isPrinting ? "header-border-print" : "header-border"
                        }
                        align="center"
                        style={{ textAlign: "center" }}
                      ></TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows(table, tableIndex, readingRowsLocal, range)}
                </TableBody>
              </Table>
            </EditandViewTable>
          </div>
          {!isPrinting ? (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  margin: "10px 0px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  id="datasheet_editdatasheet_readingadd"
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    addStaticReadingRow(table.id);
                    setEditingRowIndex(readingRowsLocal.length);
                  }}
                >
                  <AddIcon /> Reading(s)
                </Button>
                <Button
                  id="datasheet_editdatasheet_readingunit"
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    addStaticUnitRow(table.id);
                    setEditingRowIndex(readingRowsLocal.length);
                  }}
                >
                  <AddIcon /> Unit
                </Button>
                <Button
                  id="datasheet_editdatasheet_readingheader"
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    addStaticRowHeader(table.id);
                    setEditingRowIndex(readingRowsLocal.length);
                  }}
                >
                  <AddIcon /> Row header
                </Button>
              </div>
              <Divider
                sx={{
                  backgroundColor: "black",
                  height: "2px",
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  const renderComplianceStatus = () => {
    let color = "black";
    let complianceStatus = "N/A";
    if (readingRowsLocal?.length > 0) {
      if (indicators_complianceStatus == true) {
        color = "green";
        complianceStatus = "Pass";
      } else if (indicators_complianceStatus == false) {
        color = "red";
        complianceStatus = "Fail";
      }
    }
    return (
      <div>
        <h5 style={{ color: color }}>
          Compliance Status:
          {complianceStatus}
        </h5>
      </div>
    );
  };

  return (
    <div>
      {!isPrinting && (
        <>
          <div>
            <div className="sopVideocontainer">
              <div className="sopcontainer">
                {instrumentDetails?.sopUpload && (
                  <div
                    onClick={() =>
                      handleOpenModalResource(instrumentDetails?.sopUpload)
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    <InsertDriveFileIcon style={{ color: "#1976d2" }} />
                    <Typography
                      variant="span"
                      component="span"
                      style={{ marginLeft: "0.3rem" }}
                    >
                      SOP
                    </Typography>
                  </div>
                )}
                {instrumentDetails?.videoUpload && (
                  <div
                    onClick={() =>
                      handleOpenModalResource(instrumentDetails?.videoUpload)
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    <YouTubeIcon style={{ color: "#1976d2" }} />
                    <Typography
                      variant="span"
                      component="span"
                      style={{ marginLeft: "0.3rem" }}
                    >
                      Video
                    </Typography>
                  </div>
                )}
              </div>
              <div className="Observedcontainer">
                <Typography variant="h6" component="div">
                  Observed Readings
                </Typography>
              </div>
            </div>
            <br />
            {settings?.["Datasheet Autoload"] == "true" && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  size="small"
                  sx={{
                    minWidth: 180,
                  }}
                  options={jobNumbers}
                  getOptionLabel={(option) => option.jobNumber || ""}
                  onChange={(event, newValue) =>
                    setSelectedselectedDatasheetID(
                      newValue ? newValue.id : null
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Job Number"
                      variant="outlined"
                    />
                  )}
                />
                {selectedDatasheetID && (
                  <Autocomplete
                    size="small"
                    sx={{
                      minWidth: 180,
                    }}
                    options={[
                      { value: true, label: "Without Master" }
                    ]}
                    getOptionLabel={(option) => option.label || ""}
                    onChange={(event, newValue) =>
                      setSelectedMAsterORNot(
                        newValue ? newValue.value : false
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Master"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                <Button
                  size="sm"
                  onClick={() => {
                    if (selectedDatasheetID) {
                      fetchafterAutoloadJobNumber(
                        datasheetId,
                        datasheetStaticReadings,
                        datasheetDetails,
                        dispatch,
                        selectedDatasheetID,
                        selectedMasterORNot,
                      );
                    }
                  }}
                >
                  Autoload
                </Button>
                <p>No of Reading : {datasheetStaticReadings.length}</p>
              </Box>
            )}
          </div>
          <hr style={{ border: "1px solid black" }} />
        </>
      )}

      <div className="ds-tables">{renderReadingTables()}</div>

      {!isPrinting && (
        <>
          <hr style={{ border: "1px solid black" }} />
          {renderComplianceStatus()}
        </>
      )}
      {renderInstruntionModal()}

      <ResourceModel
        open={openModalResource}
        onClose={handleCloseModalResource}
        resourceUrl={modalResourceUrl}
      />
    </div>
  );
}
